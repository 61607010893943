import React, { useEffect } from 'react'
import '../styles/contact.css';
import LanguageSelector from '../components/UI/LanguageSelector'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
    width: number;
}

const containerStyle = {
    width: '100%',
    height: '100%'
};

const center = {
    lat: -3.745,
    lng: -38.523
};


function Contact(props: Props) {
    const { width } = props;
    const { t } = useTranslation();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "YOUR_API_KEY"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map: any) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, [])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <div className='contact'>
            <div className='contact_left'>

                <div className='contact_left_content'>

                <Link to="/"><img className='logo' src={process.env.PUBLIC_URL + '/logo3.png'} alt="" /></Link>

                    <div className='contact_left_content_body'>
                        <div>
                            <h6>{t('contact_page.title1')}</h6>
                            <div><img src={process.env.PUBLIC_URL + '/phone.png'} alt="" /><p>+90 (501) 093 19 80</p><img src={process.env.PUBLIC_URL + '/ru.png'} alt="" /><img src={process.env.PUBLIC_URL + '/en.png'} alt="" /></div>
                        </div>
                        <div>
                            <h6>{t('contact_page.title2')}</h6>
                            <div><img src={process.env.PUBLIC_URL + '/location.png'} alt="" /><p>{t('contact_page.address')}</p></div>
                            <div><img src={process.env.PUBLIC_URL + '/phone.png'} alt="" /><p>+7 (916) 186 16 18 (WhatsApp)</p><img src={process.env.PUBLIC_URL + '/ru.png'} alt="" /><img src={process.env.PUBLIC_URL + '/en.png'} alt="" /></div>
                            <div><img src={process.env.PUBLIC_URL + '/phone.png'} alt="" /><p>+90 (501) 093 19 80</p><img src={process.env.PUBLIC_URL + '/ru.png'} alt="" /><img src={process.env.PUBLIC_URL + '/en.png'} alt="" /></div>
                        </div>
                    </div>

                </div>

            </div>
            <div className='contact_right'>
                <img src={width <= 768 ? width <= 400 ? process.env.PUBLIC_URL + '/img/map-mobile.png' : process.env.PUBLIC_URL + '/img/map-tablet.png' : process.env.PUBLIC_URL + '/img/map.png'} alt="" />
                {/* {
                    isLoaded ? (
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={10}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                        >
                            <></>
                        </GoogleMap>
                    ) : <></>
                } */}
            </div>
            <div className='contact_right_lang'>
                <LanguageSelector />
            </div>
        </div>
    )
}

export default Contact
