import React from 'react';
import '../styles/mainlayout.css';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ImageList from '../components/UI/ImageList';


interface Props {
    title: string;
    image: string;
    isAloneButton: boolean;
    isDetail: boolean;
}

function MainLayout(props: Props) {
    const { image, isAloneButton, title, isDetail } = props;

    return (
        <div className='main_layout'>
            <div className='main_layout_body'>
                {
                    !isDetail
                    ?
                    <Header image={image} isAloneButton={isAloneButton} title={title}/>
                    :
                    <></>
                }
                <main><Outlet /></main>
                <Footer />
            </div>
        </div>
    )
}

export default MainLayout
