import React from 'react'
import InstaImageItem from './InstaImageItem';

import "../../styles/instaImages.css";
import { useTranslation } from 'react-i18next';

interface Props {
    images: string[];
    width: number;
}

function InstaImages(props: Props) {
    const { images, width } = props;
    const { t } = useTranslation();

    return (
        <div className='insta_images'>
            <div className='insta_images_items'>
                {
                    images.map((image, index) => {
                        if (width <= 540) {
                            if (index <= 3) {
                                return <div><InstaImageItem key={index} id={index} image={image} /></div>
                            }
                        } else {
                            return <div><InstaImageItem key={index} id={index} image={image} /></div>
                        }

                    })
                }
            </div>
        </div>
    )
}

export default InstaImages
