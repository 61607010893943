import React from 'react'
import { useNavigate } from 'react-router-dom';
import "../styles/notFound.css";

interface Props {}

function NotFoundPage(props: Props) {
    const {} = props;
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <div className="not-found-container">
            <div className="not-found-content">
                <h1 className="not-found-title">404</h1>
                <p className="not-found-text">Oops! The page you're looking for doesn't exist.</p>
                <button className="not-found-button" onClick={handleGoHome}>
                    Go Back Home
                </button>
            </div>
        </div>
    )
}

export default NotFoundPage
