import React from 'react'
import { BsArrowRight } from "react-icons/bs";

import '../../styles/button.css';
import { Link } from 'react-router-dom';

interface Props {
    title: string;
    to: string;
}

function GoldButton(props: Props) {
    const { title, to } = props

    return (
        <Link to="">
            <button className='gold-button'>
                {title}
                <BsArrowRight />
            </button>
        </Link>
    )
}

export default GoldButton
