import React from 'react'
import '../../styles/card.css';
import { Link } from 'react-router-dom';


interface Props {
    id: number;
    src: string;
    title: string;
    description: string;
}

function Card(props: Props) {
    const { id, src, title, description } = props

    return (
        <div className='card'>
            <Link to="">
                <img src={process.env.PUBLIC_URL + "/img/" + src} alt="" />
                <div className='card_body'>
                    <h5>{title}</h5>
                    <p>{description}</p>
                </div>
            </Link>
        </div>
    )
}

export default Card
