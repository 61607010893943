import React, { useEffect, useState } from 'react';
import '../../styles/languageSelector.css';
import { useTranslation } from 'react-i18next';

interface LanguageOption {
  code: string;
  label: string;
}

const languages: LanguageOption[] = [
  { code: 'ru', label: 'RU' },
  { code: 'tr', label: 'TR' },
  { code: 'en', label: 'EN' },
];

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageOption>(() => {
    const storedLang = localStorage.getItem('lang');
    
    if (storedLang) {
      return JSON.parse(storedLang) as LanguageOption;
    }

    const browserLang = navigator.language.slice(0, 2); // Get the first two characters of the browser language
    const defaultLang = languages.find(lang => lang.code === browserLang) || languages[0];
    
    return defaultLang;
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const storedLang = localStorage.getItem('lang');
    
    if (storedLang) {
      const il8nLang = JSON.parse(storedLang) as LanguageOption;
      i18n.changeLanguage(il8nLang.code);
    } else {
      // If no stored language, use the browser's language
      i18n.changeLanguage(selectedLanguage.code);
    }
  }, [i18n, selectedLanguage]);

  const handleLanguageChange = (lang: LanguageOption) => {
    i18n.changeLanguage(lang.code);
    setSelectedLanguage(lang);
    localStorage.setItem('lang', JSON.stringify(lang));
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="language-selector">
      <button onClick={toggleDropdown} className="dropdown-button">
        <img
          src={`${process.env.PUBLIC_URL}/${selectedLanguage.code}.png`}
          alt={selectedLanguage.label}
          className="flag-icon"
        />
        <p>{selectedLanguage.label}</p>
        <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}>&#9662;</span>
      </button>

      {isOpen && (
        <ul className="dropdown-list">
          {languages.map((lang) => (
            <li
              key={lang.code}
              className={`dropdown-item ${lang.code === selectedLanguage.code ? 'selected' : ''}`}
              onClick={() => handleLanguageChange(lang)}
            >
              <img
                src={`${process.env.PUBLIC_URL}/${lang.code}.png`}
                alt={lang.label}
                className="flag-icon"
              />
              <p>{lang.label}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSelector;
