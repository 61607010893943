import React from 'react'
import { FaInstagram } from 'react-icons/fa';
import '../../styles/listview.css';


interface Props {
    image: string;
}

function ImageItem(props: Props) {
    const { image } = props

    return (
        <div className="image-item">
            <img src={process.env.PUBLIC_URL + '/img/' + image} alt="" className="image-item_image" />
            <div className="image-item_overlay">
                <div className="image-item_text"><FaInstagram /></div>
            </div>
        </div>
    )
}

export default ImageItem
