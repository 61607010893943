import { IProduct } from "../models/product.model";

export const images: string[] =[
    '/img1.png',
    '/img2.png',
    '/img3.png',
    '/img4.png'
];

export const diploms: string[] =[
    '/diplom1.png',
    '/diplom2.png',
    '/diplom3.png',
    '/diplom4.png',
    '/diplom5.png',
    '/diplom6.png'
];

export const markaArray: string[] = [
    '/migros.png',
    '/rixos.png',
    '/carrefour.png',
    '/macrocenter.png',
    '/migros.png',
    '/rixos.png'
]


export const product: IProduct[]  = [
    {
        id: 1,
        title: 'Филе индейки холодного копчения',
        image: 'image1.png',
        price: 1500,
        categoryId: 3,
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel sapien vel ipsum placerat sagittis. Sed euismod, enim vitae ultricies vestibulum, velit felis sagittis neque, id interdum mi neque sit amet nisi.',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel sapien vel ipsum placerat sagittis. Sed euismod, enim vitae ultricies vestibulum, velit felis sagittis neque, id interdum mi neque sit amet nisi.'
    },
    {
        id: 2,
        title: 'Куриное филе горячего копчения',
        image: 'image2.png',
        price: 1500,
        categoryId: 2,
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel sapien vel ipsum placerat sagittis. Sed euismod, enim vitae ultricies vestibulum, velit felis sagittis neque, id interdum mi neque sit amet nisi.',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel sapien vel ipsum placerat sagittis. Sed euismod, enim vitae ultricies vestibulum, velit felis sagittis neque, id interdum mi neque sit amet nisi.'
    },
    {
        id: 3,
        title: 'Куриные колбаски для гриля (класик)',
        image: 'image3.png',
        price: 1500,
        categoryId: 2,
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel sapien vel ipsum placerat sagittis. Sed euismod, enim vitae ultricies vestibulum, velit felis sagittis neque, id interdum mi neque sit amet nisi.',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel sapien vel ipsum placerat sagittis. Sed euismod, enim vitae ultricies vestibulum, velit felis sagittis neque, id interdum mi neque sit amet nisi.'
    },
    {
        id: 4,
        title: 'Колбаски для гриля куриные с моцареллой',
        image: 'image4.png',
        price: 1500,
        categoryId: 2,
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel sapien vel ipsum placerat sagittis. Sed euismod, enim vitae ultricies vestibulum, velit felis sagittis neque, id interdum mi neque sit amet nisi.',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel sapien vel ipsum placerat sagittis. Sed euismod, enim vitae ultricies vestibulum, velit felis sagittis neque, id interdum mi neque sit amet nisi.'
    },
    {
        id: 5,
        title: 'Колбаски “охотничьи” из курицы с пармезаном',
        image: 'image5.png',
        price: 1500,
        categoryId: 2,
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel sapien vel ipsum placerat sagittis. Sed euismod, enim vitae ultricies vestibulum, velit felis sagittis neque, id interdum mi neque sit amet nisi.',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel sapien vel ipsum placerat sagittis. Sed euismod, enim vitae ultricies vestibulum, velit felis sagittis neque, id interdum mi neque sit amet nisi.'
    },
    {
        id: 6,
        title: 'Колбаски “охотничьи” из курицы с пармезаном',
        image: 'image6.png',
        price: 1500,
        categoryId: 2,
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel sapien vel ipsum placerat sagittis. Sed euismod, enim vitae ultricies vestibulum, velit felis sagittis neque, id interdum mi neque sit amet nisi.',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel sapien vel ipsum placerat sagittis. Sed euismod, enim vitae ultricies vestibulum, velit felis sagittis neque, id interdum mi neque sit amet nisi.'
    },
    {
        id: 7,
        title: 'Ветчина из индейки',
        image: 'image7.png',
        price: 1500,
        categoryId: 3,
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel sapien vel ipsum placerat sagittis. Sed euismod, enim vitae ultricies vestibulum, velit felis sagittis neque, id interdum mi neque sit amet nisi.',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel sapien vel ipsum placerat sagittis. Sed euismod, enim vitae ultricies vestibulum, velit felis sagittis neque, id interdum mi neque sit amet nisi.'
    },
    {
        id: 8,
        title: 'Утиная грудка горячего копчения',
        image: 'image8.png',
        price: 1500,
        categoryId: 4,
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel sapien vel ipsum placerat sagittis. Sed euismod, enim vitae ultricies vestibulum, velit felis sagittis neque, id interdum mi neque sit amet nisi.',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel sapien vel ipsum placerat sagittis. Sed euismod, enim vitae ultricies vestibulum, velit felis sagittis neque, id interdum mi neque sit amet nisi.'
    }
]