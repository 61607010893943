import React, { SyntheticEvent, TouchEventHandler, useEffect, useLayoutEffect, useState } from 'react'
import '../../styles/imageList.css';
import { FaInstagram } from 'react-icons/fa';
import ImageItem from './ImageItem';
import { useResizeDetector } from 'react-resize-detector';


interface Props { }

const PAGE_SIZE = 1440

function ImageList(props: Props) {
    const { } = props;
    const [offset, setOffset] = useState<number>(0);
    const [x1, setX1] = useState<number>(0);
    const [x2, setX2] = useState<number>(0);

    const { width, height, ref } = useResizeDetector();
    useEffect(() => {
        console.log(width);
    }, [width]);

    const onTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        const touchX = event.touches[0].clientX;
        setX1(touchX)
        console.log("onTouchStart X position: ", touchX);
    };


    const onTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        const touchX = event.touches[0].clientX;
        setX2(touchX)
        const diff = x1 - x2;
        
        
        if(diff < 0){
            const diffX = PAGE_SIZE - (diff*(-1));
            if(diffX <= PAGE_SIZE){
                setOffset(diffX);
                console.log("onTouchMove X position: ", diff*(-1));
            }
        }else if(diff > 0){
            const diffX = PAGE_SIZE - diff;
            if(diffX >= PAGE_SIZE){
                setOffset(-diffX);
                console.log("onTouchMove X position: ", diff*(-1));
            }
        }

        // if (diffX < 1440 || diffX > 0)
        //     setOffset(diffX)
    };


    return (
        <div className='image_list'>
            <div
                ref={ref}
                className='image_list_body'
                onTouchMove={onTouchMove}
                onTouchStart={onTouchStart}
                style={
                    {
                        transform: `translateX(-${offset}px)`
                    }}
            >
                <div><ImageItem image='/img1.png' /></div>
                <div><ImageItem image='/img2.png' /></div>
                <div><ImageItem image='/img3.png' /></div>
                <div><ImageItem image='/img4.png' /></div>
                <div><ImageItem image='/img5.png' /></div>
            </div>
        </div>
    )
}

export default ImageList
