import React, { useEffect, useState } from 'react'
import '../styles/catalog.css';
import ImageList from '../components/UI/ImageList'
import ListView from '../components/UI/ImageView'
import { diploms, product } from '../store/data'
import Card from '../components/UI/Card'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DiplomView from '../components/UI/DiplomView';

interface Data {
    id: number;
    name: string;
}

const menu: Data[] = [
    {
        id: 1,
        name: 'Все продукты (8)'
    },
    {
        id: 2,
        name: 'Курица (5)'
    },
    {
        id: 3,
        name: 'Индейка (2)'
    },
    {
        id: 4,
        name: 'Утка (1)'
    },
]

interface Props { }

function Catalog(props: Props) {
    const { } = props;
    const { t } = useTranslation();
    const [isActive, setIsctive] = useState<number>(1)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='catalog'>
            <div className='catalog_content'>
                <div className='catalog_content_menu'>
                    {
                        menu.map((item, index) => (
                            <div
                                key={item.id}
                                className={isActive === item.id ? 'catalog_content_menu_item catalog_content_menu_item_active' : 'catalog_content_menu_item'}
                                onClick={() => setIsctive(item.id)}
                            >
                                <p>{item.name}</p>
                            </div>
                        ))
                    }
                </div>
                <div className='catalog_content_items'>
                    {
                        product.map((item) => {
                            if (isActive !== 1) {
                                if (item.categoryId === isActive) {
                                    return <div> <Card
                                        id={item.id}
                                        src={item.image}
                                        title={item.title}
                                        description={item.description}
                                    />
                                    </div>
                                }
                            } else {
                                return <div> <Card
                                    id={item.id}
                                    src={item.image}
                                    title={item.title}
                                    description={item.description}
                                />
                                </div>
                            }
                        })
                    }
                </div>
            </div>
            {/* Diploms Box */}
            {/* start */}
            <DiplomView
                title={t('diploms_box.title')}
                imageList={diploms}
            />
            {/* end */}
            <ImageList />
        </div>
    )
}

export default Catalog
