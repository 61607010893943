import React, { useEffect } from 'react'
import "../styles/home.css";
import { diploms, images, markaArray, product } from '../store/data';
import { useTranslation } from 'react-i18next';
import ProductBox from '../components/UI/ProductBox';
import DiplomView from '../components/UI/DiplomView';
import ImageView from '../components/UI/ImageView';
import ListView from '../components/UI/ListView';
import ProductView from '../components/UI/ProductView';
import ImageList from '../components/UI/ImageList';
import InstaImages from '../components/UI/InstaImages';

interface Props { 
    width: number;
}

function Home(props: Props) {
    const { width } = props;
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='home'>
            {/* About Box */}
            {/* start */}
            <div className='about_box'>
                <div className='about_box_body'>
                    <div className='about_box_img'>
                        <img src={process.env.PUBLIC_URL + '/gargatua.png'} alt="" />
                    </div>
                    <div className='about_box_content'>
                        <div className='about_box_content_wrap'>
                            <div>
                                <p>{t('hero.subtitle')}</p>
                                <h1>{t('hero.title')}</h1>
                            </div>
                            <div>
                                <p>{t('hero.content1')}</p>
                                <p>{t('hero.content2')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* end */}

            {/* Product Box */}
            {/* start */}
            {/* <ProductView/> */}
            {/* end */}
            {/* Clients Box */}
            {/* start */}
            {/* <ListView
                title={t('clients_box.title')}
                imageList={markaArray}
            /> */}
            {/* end */}

            {/* Info Box */}
            {/* start */}
            <div className='info_box'>
                <div className='info_box_left'>
                    <div className='info_box_left_header'>
                        <p>{t('info_box.sub_title')}</p>
                        <h4>{t('info_box.title')}</h4>
                    </div>
                    <div className='info_box_left_content'>
                        <p>{t('info_box.content1')}</p>
                        <p>{t('info_box.content2')}</p>
                        <p>{t('info_box.content3')}</p>
                    </div>
                </div>
                <div className='info_box_right'>
                    <img src={process.env.PUBLIC_URL + '/img/human.png'} alt="" />
                </div>
            </div>
            {/* end */}

            {/* Video Box */}
            {/* start */}
            {/* <div className='video_box'>
                <div className='video_box_content'>
                    <div className='video_box_left'>
                        <img src={process.env.PUBLIC_URL + '/img/video.png'} alt="" />
                    </div>
                    <div className='video_box_right'>
                        <h4>{t('video_box.title')}</h4>
                        <p>{t('video_box.content')}</p>
                    </div>
                </div>
            </div> */}
            {/* end */}

            {/* Diploms Box */}
            {/* start */}
            {/* <DiplomView
                title={t('diploms_box.title')}
                imageList={diploms}
            /> */}
            {/* end */}

            <InstaImages
                images={images}
                width={width}
            />

            {/* <ImageView title="" imageList={images}/> */}
        </div>
    )
}

export default Home
