import React, { useLayoutEffect, useState } from 'react'
import '../../styles/diplomview.css';
import Slider from 'react-slick';


interface Props {
    title: string;
    imageList: string[];
}



function DiplomView(props: Props) {
    const { title, imageList } = props
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 4
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 3
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            }
        ]
    };


    return (
        <div className='diplom_view'>
            <div className='diplom_view_body'>
                <div className='diplom_view_header'>
                    <h3>{title}</h3>
                </div>
                <div className='diplom_view_content'>
                    <Slider {...settings} >
                        {
                            imageList.map((image, index) => (
                                <div key={index} className='diplom_view_content_item'><img src={process.env.PUBLIC_URL + '/img/' + image} alt="" /></div>
                            ))
                        }
                    </Slider>

                </div>
            </div>
        </div>
    )
}

export default DiplomView
