import React from 'react'
import "../styles/footer.css";
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



interface Props { }

function Footer(props: Props) {
    const { } = props;
    const { t } = useTranslation();

    return (
        <footer>
            <div className='footer_content'>
                <div className='footer_content_left'>
                    <img src={process.env.PUBLIC_URL + '/logo2.png'} alt="" />
                    <h6>{t('footer.title')}</h6>
                </div>
                <div className='footer_content_right'>
                    <ul>
                        <li><Link to="/"><p>{t('footer.menu.home')}</p></Link></li>
                        <li><Link to="/catalog"><p>{t('footer.menu.catalog')}</p></Link></li>
                        <li><Link to="/contact"><p>{t('footer.menu.contact')}</p></Link></li>
                    </ul>
                    <ul>
                        <li><Link to="https://www.instagram.com/pavelfood?igsh=MWZvNjZneXJ4OHdrZg=="><FaInstagram /></Link></li>
                        <li><Link to="https://www.facebook.com/share/qhQ4Uv62X8Zqg5ZC/?mibextid=LQQJ4d"><FaFacebook /></Link></li>
                        {/* <li><Link to=""><FaTiktok /></Link></li> */}
                    </ul>
                </div>
            </div>
            <img src={process.env.PUBLIC_URL + '/img/title.png'} alt="" />
        </footer>
    )
}

export default Footer
