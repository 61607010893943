import React, { useEffect, useState } from 'react'
import '../styles/detail.css';
import ListView from '../components/UI/ImageView';
import ImageList from '../components/UI/ImageList';
import { diploms, product } from '../store/data';
import GoldButton from '../components/UI/GoldButton';
import { useParams } from 'react-router-dom';
import { IProduct } from '../models/product.model';
import LanguageSelector from '../components/UI/LanguageSelector';
import { useTranslation } from 'react-i18next';
interface Props { }

function Detail(props: Props) {
    const { } = props;
    const { t } = useTranslation();
    let { id } = useParams();
    const [item, setItem] = useState<IProduct>({} as IProduct);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        const temp = product.filter(item => item.id === Number(id));
        setItem(temp[0]);
    }, [id])

    return (
        <div className='detail'>
            <div className='detail_header'>
                <div className='detail_left'>
                    <div className='detail_left_content'>
                        <img className='logo' src={process.env.PUBLIC_URL + '/logo.png'} alt="" />
                        <div className='detail_left_body'>
                            <h2>{item.title}</h2>
                            <div className='detail_left_body_summary'>
                                <p>{item.description}</p>
                                <h6>Рекомендации по приготовлению:</h6>
                                <p>{item.summary}</p>
                            </div>
                            <div className='detail_left_body_button'>
                                <GoldButton to='/contact' title='Связаться с нами' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='detail_right'>
                    <img className='detail_right_image' src={process.env.PUBLIC_URL + '/img/' + item.image} alt="" />
                    <img className='detail_right_img' src={process.env.PUBLIC_URL + '/halal.png'} alt="" />
                    <div className='detail_right_lang'>
                        <LanguageSelector />
                    </div>
                </div>
            </div>
            {/* Diploms Box */}
            {/* start */}
            <ListView
                title={t('diploms_box.title')}
                imageList={diploms}
            />
            {/* end */}

            <ImageList />
        </div>
    )
}

export default Detail
