import React, { useLayoutEffect, useState } from 'react'
import '../../styles/imageview.css';
import Slider from 'react-slick';
import ImageItem from './ImageItem';


interface Props {
    title: string;
    imageList: string[];
}



function ImageView(props: Props) {
    const { title, imageList } = props
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: false,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    initialSlide: 5
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    initialSlide: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    initialSlide: 4
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    initialSlide: 4
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3,
                    rows: 2,
                    slidesPerRow: 1,


                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    rows: 2,
                    slidesPerRow: 1,


                }
            }
        ]
    };


    return (
        <div className='image_view'>
            <div className='image_view_content'>
                <Slider {...settings} >
                    {
                        imageList.map((image, index) => (
                            <div key={index} className='image_view_content_item'><ImageItem image={image} /></div>
                        ))
                    }
                </Slider>

            </div>
        </div>
    )
}

export default ImageView
