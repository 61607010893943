import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import RU_LOCALE from '../locales/ru.json';
import TR_LOCALE from '../locales/tr.json';
import EN_LOCALE from '../locales/en.json';


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        // lng: 'tr',
        fallbackLng: ['ru','tr','en'],
        interpolation: {
            escapeValue: false,
        },
        resources: {
            ru: {
                translation: RU_LOCALE
            },
            tr: {
                translation: TR_LOCALE
            },
            en: {
                translation: EN_LOCALE
            }
        }
    });

export default i18n;